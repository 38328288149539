(function() {
    'use strict';

    angular.module('ionicTag', [
        'ion-autocomplete',
        'ionic',
        'ionicAlert',
        'ionicConfirmation',
        'ionicData',
        'ionicDataTable',
        'ionicFilter',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultVariable',
        'uabPromiseManagement'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicTag').service('TagCreateService', TagCreateService);

    TagCreateService.$inject = [
        'RequestManagementService',
        'TagListService'
     ];

    function TagCreateService(
        RequestManagementService,
        TagListService
    ) {
        var TagCreateService = this;

        TagCreateService.createTag = createTag;
        function createTag(newTag) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'tags');
            options = RequestManagementService.setAction(options, 'add');

            options = RequestManagementService.setData(options, newTag);

            return ListManager.create(options);
        }

        TagCreateService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = TagListService.getListManager();

        TagCreateService.reset();

        return TagCreateService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').service('TagEditService', TagEditService);

    TagEditService.$inject = [
        'RequestManagementService',
        'TagListService'
    ];

    function TagEditService(
        RequestManagementService,
        TagListService
    ) {
        var TagEditService = this;

        TagEditService.deleteTag = deleteTag;
        function deleteTag(tag) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'tags');
            options = RequestManagementService.setAction(options, 'delete', tag.id);

            return ListManager.remove(options);
        }

        TagEditService.editTag = editTag;
        function editTag(tag) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'tags');
            options = RequestManagementService.setAction(options, 'edit', tag.id);

            options = RequestManagementService.setData(options, tag);

            return ListManager.edit(options);
        }

        TagEditService.reset = reset;
        function reset() {
            ListManager.reset();
            
        }

        TagEditService.getTag = getTag;
        function getTag() {
            return ListManager.getDefault();
        }

        var ListManager = TagListService.getListManager();

        TagEditService.reset();

        return TagEditService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').service('TagListService', TagListService);

    TagListService.$inject = [
        'DefaultVariableService',
        'ListManagementService',
        'RequestManagementService'
    ];

    function TagListService(
        DefaultVariableService,
        ListManagementService,
        RequestManagementService
    ) {
        var TagListService = this;

        TagListService.buildOptions = buildOptions;
        function buildOptions(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'tags');
            options = RequestManagementService.setAction(options, 'get');

            options = RequestManagementService.setParams(
                options,
                [
                    'TagGroups'
                ]
            );

            return options;
        }

        TagListService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        TagListService.getTags = getTags;
        function getTags(options) {
            return TagListService.loadAllTags(options);
        }

        TagListService.getList = getList;
        function getList() {
            return ListManager.getList();
        }

        TagListService.getMore = getMore;
        function getMore(options) {
            options = TagListService.buildOptions(options);

            return ListManager.getMore(options);
        }

        TagListService.getPage = getPage;
        function getPage(options) {
            options = TagListService.buildOptions(options);

            return ListManager.getPage(options);
        }

        TagListService.loadAllTags = loadAllTags;
        function loadAllTags(options) {
            options = TagListService.buildOptions(options);

            options = RequestManagementService.setLimit(options, 1000);

            return ListManager.get(options);
        }

        TagListService.removeFlags = removeFlags;
        function removeFlags(tags) {
            TagListService.tags = tags.filter(
                function(tag) {
                    return tag.tag_groups.length === 0;
                }
            );
        }

        TagListService.reset = reset;
        function reset() {
            ListManager.reset();
        }

        var ListManager = ListManagementService.getListManager(
            TagListService.getTags
        );

        TagListService.reset();

        return TagListService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').service(
        'TagManagementService',
        TagManagementService
    );

    TagManagementService.$inject = [
        'TagCreateService',
        'TagEditService',
        'TagListService'
    ];

    function TagManagementService(
        TagCreateService,
        TagEditService,
        TagListService

    ) {
        var TagManagementService = this;

        TagManagementService.getTags = getTags;
        function getTags(options) {
            return TagListService.getPage(options);
        }

        TagManagementService.createTag = createTag;
        function createTag(newTag) {
            return TagCreateService.createTag(newTag);
        }

        TagManagementService.deleteTag = deleteTag;
        function deleteTag(tag) {
            return TagEditService.deleteTag(tag);
        }

        TagManagementService.editTag = editTag;
        function editTag(tag) {
            return TagEditService.editTag(tag);
        }

        TagManagementService.reset = reset;
        function reset() {

        }

        TagManagementService.reset();

        return TagManagementService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').controller('TagManagementController', TagManagementController);
    
    TagManagementController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'IonicConfirmationService',
        'LoginService',
        'TagManagementService'
    ];
    
    function TagManagementController(
        AlertService,
        DefaultVariableService,
        IonicConfirmationService,
        LoginService,
        TagManagementService
    ) {
        var TagManagementController = this;

        TagManagementController.createTag = createTag;
        function createTag() {
            TagManagementController.isCreatingTag = true;

            return TagManagementService.createTag(TagManagementController.newTag).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('TAG CREATED.');

                        TagManagementController.resetNewTag();

                        TagManagementController.showCreator = false;

                        TagManagementController.loadTags();
                    }
                }
            ).finally(
                function() {
                    TagManagementController.isCreatingTag = false;
                }
            );
        }

        TagManagementController.deleteTag = deleteTag;
        function deleteTag() {
            TagManagementController.isDeletingTag =  true;

            return IonicConfirmationService.createConfirmation(
                'ARE YOU SURE YOU WANT TO DELETE THIS TAG?',
                'DELETE',
                'CANCEL'
            ).then(
                function(confirm) {
                    if (confirm) {
                        return TagManagementService.deleteTag(
                            TagManagementController.tag
                        ).then(
                            function(response) {
                                if (response) {
                                    TagManagementController.resetTag();

                                    AlertService.addMessage('TAG DELETED.');

                                    TagManagementController.loadTags();
                                }
                            }
                        );
                    }

                    return false;
                }
            ).finally(
                function() {
                    TagManagementController.isDeletingTag =  false;
                }
            );
        }

        TagManagementController.editTag = editTag;
        function editTag() {
            TagManagementController.isEditingTag = true;

            return TagManagementService.editTag(
                TagManagementController.tag
            ).then(
                function(response) {
                    if (response) {
                        TagManagementController.resetTag();

                        AlertService.addMessage('TAG EDITED.');

                        TagManagementController.loadTags();
                    }

                    return response;
                }
            ).finally(
                function() {
                    TagManagementController.isEditingTag = false;
                }
            );
        }

        TagManagementController.loadTags = loadTags;
        function loadTags(options) {
            TagManagementController.isLoadingTags = true;

            return TagManagementService.getTags(
                options
            ).then(
                function(data) {
                    if (data) {
                        TagManagementController.tags = DefaultVariableService.getArray(
                            data.tags
                        );

                        TagManagementController.count = DefaultVariableService.getInteger(
                            data.count,
                            0
                        );
                    }

                    return data;
                }
            ).finally(
                function () {
                    TagManagementController.isLoadingTags = false;
                }
            );
        }

        TagManagementController.selectTag = selectTag;
        function selectTag(tag) {
            TagManagementController.tag = DefaultVariableService.getObject(
                tag
            );
        }

        TagManagementController.toggleCreator = toggleCreator;
        function toggleCreator() {
            TagManagementController.showCreator = !TagManagementController.showCreator;
        }

        TagManagementController.reset = reset;
        function reset() {
            TagManagementController.count = 0;

            TagManagementController.columns = [
                {
                    position: 1,
                    title:   'NAME',
                    key:     'name'
                }
            ];

            TagManagementController.isDeletingTag = false;

            TagManagementController.isEditingTag = false;

            TagManagementController.isLoadingTags = true;

            TagManagementController.resetNewTag();

            TagManagementController.showCreator = false;

            TagManagementController.resetTag();
        }

        TagManagementController.resetNewTag = resetNewTag;
        function resetNewTag() {
            TagManagementController.newTag = {};
        }

        TagManagementController.resetTag = resetTag;
        function resetTag() {
            TagManagementController.tag = DefaultVariableService.getObject();
        }

        TagManagementController.init = init;
        function init() {
            TagManagementController.reset();
            TagManagementController.loadTags();
        }

        LoginService.register(TagManagementController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').directive('ionicTagManagement', ionicTagManagement);

    function ionicTagManagement() {
        return {
            controller:   'TagManagementController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div data-ng-if="!ctrl.isLoadingTags"><div data-ng-if="!ctrl.showCreator"><div data-ng-if="ctrl.tag.id === 0"><button class="button button-full accept-button row" data-ng-click="ctrl.toggleCreator()">CREATE TAG</button><ionic-data-table columns="ctrl.columns" objects="ctrl.tags" on-request="ctrl.loadTags" on-select="ctrl.selectTag" total="ctrl.count"></ionic-data-table></div><div data-ng-if="ctrl.tag.id !== 0"><label class="item item-input item-floating-label row"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.tag.name"></label><div class="row"><button class="button button-full accept-button col-xl-4" data-ng-click="ctrl.editTag()" data-ng-disabled="ctrl.isEditingTag || ctrl.isDeletingTag">{{ ctrl.isEditingTag ? \'EDITING...\' : \'EDIT\' }}</button> <button class="button button-full decline-button col-xl-4" data-ng-click="ctrl.deleteTag()" data-ng-disabled="ctrl.isEditingTag || ctrl.isDeletingTag">{{ ctrl.isDeletingTag ? \'DELETING...\' : \'DELETE\' }}</button> <button class="button button-full col-xl-4" data-ng-click="ctrl.resetTag()" data-ng-disabled="ctrl.isEditingTag || ctrl.isDeletingTag">CANCEL</button></div></div></div><div data-ng-if="ctrl.showCreator"><label class="item item-input item-floating-label row"><span class="input-label">NAME</span> <input type="text" placeholder="NAME" data-ng-model="ctrl.newTag.name"></label><div class="row"><button class="button button-full accept-button col-xl-6" data-ng-click="ctrl.createTag()" data-ng-disabled="ctrl.isCreatingTag">{{ ctrl.isCreatingTag ? \'CREATING...\' : \'CREATE\' }}</button> <button class="button button-full decline-button col-xl-6" data-ng-click="ctrl.toggleCreator()" data-ng-disabled="ctrl.isCreatingTag">CANCEL</button></div></div></div><span data-ng-if="ctrl.isLoadingTags"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').controller(
        'TagSelectController',
        TagSelectController
    );

    TagSelectController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'PromiseManagementService',
        'RequestManagementService',
        '$scope',
        'TagListService'
    ];

    function TagSelectController(
        DefaultVariableService,
        LoginService,
        PromiseManagementService,
        RequestManagementService,
        $scope,
        TagListService
    ) {
        var TagSelectController = this;

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                TagSelectController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }.bind(TagSelectController)
        );

        $scope.$watch(
            'options',
            function(options) {
                TagSelectController.options = RequestManagementService.getRequest(
                    options
                );

                TagSelectController.options = RequestManagementService.setPage(
                    TagSelectController.options,
                    1
                );
            }.bind(TagSelectController)
        );

        $scope.$watch(
            'target',
            function(target) {
                var candidate = DefaultVariableService.getArray(
                    target
                );

                if (!DefaultVariableService.isDefined(TagSelectController.target) || candidate.length !== TagSelectController.target.length) {
                    TagSelectController.target = candidate;
                }
            }.bind(TagSelectController)
        );

        TagSelectController.clear = clear;
        function clear() {
            TagListService.reset();
            TagSelectController.reset();
        }

        TagSelectController.loadTag = loadTag;
        function loadTag(tag) {
            return tag;
        }

        TagSelectController.loadTags = loadTags;
        function loadTags(options) {
            if (TagSelectController.tags.length === 0) {
                options = RequestManagementService.getRequest(options);
                
                options = RequestManagementService.mergeRequests(
                    TagSelectController.options,
                    options
                );
                
                return TagListService.getPage(
                    options
                ).then(
                    function (data) {
                        if (data) {
                            TagSelectController.tags = DefaultVariableService.getArray(
                                data.tags
                            );
                        }

                        return data;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(
                TagSelectController.tags
            );
        }

        TagSelectController.notify = notify;
        function notify(tags) {
            TagSelectController.onSelect(tags);
        }

        TagSelectController.reset = reset;
        function reset() {
            TagSelectController.selectedTags = [];

            TagSelectController.tags = [];
        }

        TagSelectController.init = init;
        function init() {
            TagSelectController.reset();
        }

        LoginService.register(TagSelectController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicTag').directive('ionicTagSelect', ionicTagSelect);

    function ionicTagSelect() {
        return {
            controller:   'TagSelectController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                onSelect: '=',
                options:  '=',
                target:   '='
            },
            template:'<div><ionic-filter objects="ctrl.tags" on-filter="ctrl.loadTags" on-select="ctrl.notify" target="ctrl.target" title="name" toggle-message="SELECT TAGS..."></ionic-filter></div>'
        };
    }
})();